import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {RegelLoeshenComponent} from "./regel-loeshen.component";
import {SelectRuleComponent} from "./select-rule/select-rule.component";

const routes: Routes = [
  { path: '', component: RegelLoeshenComponent },
  { path: 'selectRule', component: SelectRuleComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegelloeshenRoutingModule { }
