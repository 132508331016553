<mat-dialog-content>
  <div class="close-dialog">
    <button mat-icon-button (click)="onCansel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dialog-content">
    <app-select-rule (valuesSelected)="receiveValues($event.selectedRules)"></app-select-rule>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="form-buttons">
  <button mat-fab extended color="primary" type="submit" (click)="onCansel()">Cancel</button>
  <button mat-fab extended color="primary" type="submit" (click)="onSelect()" >Select</button>
</mat-dialog-actions>
