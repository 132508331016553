

  <mat-card class="create-request-card">

    <mat-card-title>Create a New Change Request</mat-card-title>
    <mat-card-header>BWI Rule Removal Request</mat-card-header>
    <br>
    <mat-divider></mat-divider>


    <mat-card-content>
      <form [formGroup]="ruleDeleteForm">

        <div class="form-header">
          <div class="form-buttons">
            <button mat-fab extended color="primary" type="submit" (click)="onSaveDraft()">Save Draft</button>
            <button mat-fab extended color="primary" type="button" (click)="nextStep()">Next</button>
          </div>
        </div>

        <p>Create a New Change Request </p>



        <div>
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Subject</mat-label>
            <input required matInput placeholder="Enter subject" formControlName="subject">
            <mat-error *ngIf="ruleDeleteForm.get('subject')?.hasError('required')">
              Subject is required
            </mat-error>
          </mat-form-field>
        </div>



        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Describe the Issue</mat-label>
          <textarea matInput formControlName="issueDescription" ></textarea>
        </mat-form-field>


        <div class="form-group" appearance="outline">
          <label class="attachments-label">Attachments</label>
          <input type="file" multiple (change)="onFileSelected($event)" #fileInput>
          <button type="button" mat-fab extended color="primary" (click)="fileInput.click()">
            <mat-icon>attach_file</mat-icon>
            Add more files
          </button>
          <ul>
            @for (item of files; track $index) {
            <li>{{ item.name }}</li>
            }
          </ul>
        </div>

        <div>
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Due</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="due">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error *ngIf="ruleDeleteForm.get('due')?.hasError('required')">
              Due date is required
            </mat-error>
          </mat-form-field>
        </div>


        <div class="form-row">
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Requestor</mat-label>
            <input matInput required placeholder="Enter requestor" value="" formControlName="requestor" readonly >
            <mat-error *ngIf="ruleDeleteForm.get('requestor')?.hasError('required')">
              Requestor is required
            </mat-error>
            <mat-error *ngIf= "ruleDeleteForm.get('requestor')?.hasError('email') && ruleDeleteForm.get('requestor')?.touched">
              Please enter a valid email address.
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Requestor Group</mat-label>
            <mat-select formControlName="requestorGroup">
              <mat-option value="group1">Group 1</mat-option>
              <mat-option value="group2">Group 2</mat-option>
            </mat-select>
            <mat-error *ngIf="ruleDeleteForm.get('requestorGroup')?.hasError('required') && ruleDeleteForm.get('requestorGroup')?.touched">
              Requestor Group is required.
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Device Name</mat-label>
          <mat-select required formControlName="deviceName" (selectionChange)="onDeviceChange($event)">
            <mat-option *ngFor="let device of devices" [value]="device.display_name">{{ device.display_name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="ruleDeleteForm.get('deviceName')?.hasError('required')">
            Device Name is required
          </mat-error>
        </mat-form-field>

      </form>
    </mat-card-content>
  </mat-card>


<div class="form-container">
  <mat-label>Rule to remove:</mat-label>
  <mat-card>
    <mat-card-content>
      <div>
        <button mat-fab extended color="primary" (click)="openSelectRuleDialog()" [disabled]="!selectedDevice">
          {{ selctedRules && selctedRules.length > 0 ? 'Reselect Rules' : 'Select Rules' }}
        </button>
      </div>
      <div *ngIf="selctedRules.length > 0" class="selected-rules-table">
        <div *ngFor="let rule of selctedRules" class="rule-container">
          <table mat-table [dataSource]="[rule]" class="mat-elevation-z8">

            <!-- Source-Spalte -->
            <ng-container matColumnDef="source">
              <th mat-header-cell *matHeaderCellDef>SOURCE</th>
              <td mat-cell *matCellDef="let rule">{{ rule.source.join(', ') }}</td>
            </ng-container>

            <!-- User-Spalte -->
            <ng-container matColumnDef="user">
              <th mat-header-cell *matHeaderCellDef>USER</th>
              <td mat-cell *matCellDef="let rule">{{ rule.user }}</td>
            </ng-container>

            <!-- Ziel-Spalte -->
            <ng-container matColumnDef="destination">
              <th mat-header-cell *matHeaderCellDef>DESTINATION</th>
              <td mat-cell *matCellDef="let rule">{{ rule.destination.join(', ') }}</td>
            </ng-container>

            <!-- Dienst-Spalte -->
            <ng-container matColumnDef="service">
              <th mat-header-cell *matHeaderCellDef>SERVICE</th>
              <td mat-cell *matCellDef="let rule">{{ rule.service.join(', ') }}</td>
            </ng-container>

            <!-- Aktions-Spalte -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>ACTION</th>
              <td mat-cell *matCellDef="let rule">{{ rule.action }}</td>
            </ng-container>

            <!-- Comment-Spalte -->
            <ng-container matColumnDef="comment">
              <th mat-header-cell *matHeaderCellDef>COMMENT</th>
              <td mat-cell *matCellDef="let rule">{{ rule.comments.join(', ') }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['source', 'user', 'destination', 'service', 'action', 'comment']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['source', 'user', 'destination', 'service', 'action', 'comment']"></tr>
          </table>

          <mat-divider class="divider"></mat-divider>
        </div>
      </div>

      <form [formGroup]="ruleDeleteForm">
        <div class="action-selection">
          <mat-label class="action-label">Requested Action:</mat-label>
          <div class="radio-buttons-container">
            <mat-radio-group formControlName="action" aria-label="Select an action">
              <mat-radio-button value="disable">Disable rule</mat-radio-button>
              <mat-radio-button value="remove">Remove rule</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </form>


    </mat-card-content>
  </mat-card>

  <mat-label>External change request id</mat-label>
  <mat-form-field appearance="outline" class="input-field">
    <input matInput placeholder="">
  </mat-form-field>


  <div class="form-header">
    <button mat-fab extended color="primary" class="back-button" type="button" (click)="prevStep()">Back</button>
    <div class="form-buttons">
      <button mat-fab extended color="primary" type="submit" (click)="onSaveDraft()">Save Draft</button>
      <button mat-fab extended color="primary" type="button" (click)="nextStep()">Next</button>
    </div>
  </div>
</div>

  <div *ngIf="isSubmitting" class="full-screen-spinner">
    <mat-spinner></mat-spinner>
  </div>
