import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../Shared/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {SelectRuleDialogComponent} from "./select-rule-dialog/select-rule-dialog.component";
import {DevicestoreService} from "../../store/devicestore.service";
import {Device} from "../../model/Device";
import {FirewallRulesStore} from "../../store/firewall-rules-store";
import {RemoveRule, RequestAction, Rule} from "../../model/FirewallRule";
import {CustomFieldKeys, FieldKeys, TemplateName} from "../../model/FieldKeys";
import {FirewallRulesRepo} from "../../repo/firewall-rules.repo";
import {ChangeRequestId} from "../../model/FWerstellen";
import {ChangeRequestRepoService} from "../../repo/change-request-repo.service";
import {FwRegeLoeshenRepo} from "../../repo/FwRegelLoeshen/fw-rege-loeshen-repo";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";


@Component({
  selector: 'app-regel-loeshen',
  templateUrl: './regel-loeshen.component.html',
  styleUrl: './regel-loeshen.component.scss'
})
export class RegelLoeshenComponent implements OnInit{
  files: File[] = [];
  ruleDeleteForm: FormGroup;
  isDialogOpen = false;
  devices: Device[] = [];
  selectedDevice: Device | null = null;
  selctedRules : Rule[] = [];
  changeRequestId: number | undefined
  isSubmitting = false;

  constructor(private fb: FormBuilder,
              private alertService: AlertService,
              public dialog: MatDialog,
              public deviceStore : DevicestoreService,
              private ruleStore : FirewallRulesStore,
              private changeRequestRepoService : ChangeRequestRepoService,
              private ruleRemoveRepo: FwRegeLoeshenRepo,
              private router: Router

  ) {
    this.ruleDeleteForm = this.fb.group({
      subject: ['', Validators.required],
      due: [''],
      issueDescription: [''],
      attachments: [''],
      requestor: ['customuser@test.de', [Validators.required, Validators.email]],
      requestorGroup: ['group1', Validators.required],
      deviceName: ['', Validators.required],
      action: ['remove', Validators.required]
    });

    this.deviceStore.getDevices()


  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      for (let i = 0; i < input.files.length; i++) {
        this.files.push(input.files[i]);
      }

    }
  }
  nextStep() {
    this.onSaveDraft();
    this.alertService.alert$.subscribe((alertStatus) => {
      if (alertStatus.type === 'success') {
        this.router.navigate([environment.routes.auswahltemplate]);
      }
    });
  }

  prevStep() {
  this.openConfirmDialog();
  }

  onSaveDraft() {
    if (this.ruleDeleteForm.valid) {
      this.isSubmitting = true;
      const ruleRemove = this.createRemoveRule();
      this.ruleRemoveRepo.removeRules(ruleRemove).subscribe({
        next: (response) => {
          this.changeRequestId = response?.data.changeRequestId;
          if (this.changeRequestId) {
            const changeRequestId = this.createChangeRequestId(this.changeRequestId);
            this.changeRequestRepoService.saveRequestId(changeRequestId).subscribe({
              next: () => {
                this.alertService.showAlert('Rule successfully removed!', 'success');
                this.ruleDeleteForm.reset();
                this.isSubmitting = false;
              },
              error: (err) => {
                console.error('Error saving ChangeRequestId:', err);
                this.alertService.showAlert('Error saving ChangeRequestId.', 'error');
                this.isSubmitting = false;
              }
            });
          } else {
            this.isSubmitting = false;
          }
        },
        error: (err) => {
          console.error('Error removing rule:', err);
          this.alertService.showAlert('Error removing rule.', 'error');
          this.isSubmitting = false;
        }
      });
    } else {
      console.error('Form is invalid');
      this.alertService.showAlert('Bitte fülle alle erforderlichen Felder aus.', 'error');
    }
  }

  openSelectRuleDialog(): void {
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(SelectRuleDialogComponent, {
      minWidth: '1200px',
      height: '800px',
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      this.selctedRules = result.selectedValue;
    });
  }

  ngOnInit(): void {
    this.deviceStore.devices$.subscribe(date =>{
     this.devices = date
    })
  }

  onDeviceChange(event: any) {
    this.selectedDevice = this.devices.find(device => device.display_name === event.value) || null;
    this.ruleStore.getRules(event.value);
  }

  createRemoveRule(): RemoveRule {
    const rawFields = [
      { key: FieldKeys.Subject, values: [this.ruleDeleteForm.get('subject')?.value] },
      { key: FieldKeys.Owner, values: ['api-admin-1@admin.de'] },
      { key: FieldKeys.ChangeRequestDescription, values: [this.ruleDeleteForm.get('issueDescription')?.value] },
      { key: FieldKeys.Requestor, values: [this.ruleDeleteForm.get('requestor')?.value] },
      { key: FieldKeys.Due, values: [this.ruleDeleteForm.get('due')?.value] },
      { key: FieldKeys.Device, values: [this.selectedDevice?.name] },
    ];

    const fields = rawFields.filter(field => field.values[0] !== null && field.values[0] !== '');

    const customFields = [
      { key: CustomFieldKeys.RequestorGroup, values: [this.ruleDeleteForm.get('requestorGroup')?.value] }
    ];
    const requestActions: RequestAction[] = this.selctedRules.map(rule => ({
      action: this.ruleDeleteForm.get('action')?.value,
      ruleId: rule.ruleId
    }));
    return {
      template: TemplateName.FWRegelLoeshen,
      requestActions: requestActions,
      fields: fields,
      customFields: customFields
    };
  }

  createChangeRequestId(id: number): ChangeRequestId{
    return {
      changeRequestId: id,
      requestor: this.ruleDeleteForm.get('requestor')?.value,
      requestorGroup: 'FW Regel löschen'
    }
  }

  openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate([environment.routes.auswahltemplate])
      } else {
      }
    });
  }

}
