import { NgModule } from '@angular/core';
import { SharedModule } from '../../Shared/shared.module';
import {RegelLoeshenComponent} from "./regel-loeshen.component";
import {RegelloeshenRoutingModule} from "./regelloeshen-routing.module";
import {SelectRuleComponent} from "./select-rule/select-rule.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatChip, MatChipGrid} from "@angular/material/chips";
import {MatSort} from "@angular/material/sort";
import {FormsModule} from "@angular/forms";
import {SelectRuleDialogComponent} from "./select-rule-dialog/select-rule-dialog.component";
import {MatDialogActions, MatDialogContainer, MatDialogContent} from "@angular/material/dialog";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    RegelLoeshenComponent,
    SelectRuleComponent,
    SelectRuleDialogComponent
  ],
    imports: [
        RegelloeshenRoutingModule,
        SharedModule,
        MatCheckbox,
        MatChip,
        MatChipGrid,
        MatSort,
        FormsModule,
        MatDialogContent,
        MatDialogActions,
        MatDialogContainer,
        MatProgressSpinner,
    ]
})
export class RegelLoeshenModule { }
