import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FirewallRulesStore} from "../../../store/firewall-rules-store";
import {Rule} from "../../../model/FirewallRule";
import {SelectionModel} from "@angular/cdk/collections";



@Component({
  selector: 'app-select-rule',
  templateUrl: './select-rule.component.html',
  styleUrl: './select-rule.component.scss'
})
export class SelectRuleComponent implements OnInit{
  displayedColumns: string[] = ['remove', 'number', 'enabled', 'source', 'user', 'destination', 'service', 'action', 'logging', 'time', 'comment', 'cliView'];
  dataSource: Rule[] = [];
  selection = new SelectionModel<Rule>(true, []);
  @Output() valuesSelected = new EventEmitter<{ selectedRules: Rule[] }>();

  constructor(private ruleStore: FirewallRulesStore) {
  }

  ngOnInit(): void {
    this.ruleStore.rules$.pipe().subscribe(data =>{
      if (data) {
        this.dataSource = data.flatMap(response => response.rules);
      }
    });
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.forEach(row => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  toggleSelection(element:Rule) {
    this.selection.toggle(element);
    this.emitSelectedValues();
  }


  emitSelectedValues() {
    this.valuesSelected.emit({ selectedRules: this.selection.selected});
  }

}
