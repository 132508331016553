import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from "@angular/material/dialog";
import {SelectRuleComponent} from "../select-rule/select-rule.component";
import {Rule} from "../../../model/FirewallRule";

@Component({
  selector: 'app-select-rule-dialog',
  templateUrl: './select-rule-dialog.component.html',
  styleUrl: './select-rule-dialog.component.scss'
})
export class SelectRuleDialogComponent {

  selectedValue : Rule[] = [];
  constructor(public dialogRef: MatDialogRef<SelectRuleComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Rule[]
  ) {}


  onCansel(){
    this.dialogRef.close();
  }

  receiveValues(selectedValue :Rule [] ) {
    this.selectedValue = selectedValue;
  }

  onSelect(){
    this.dialogRef.close({
      selectedValue: this.selectedValue,
    });
  }
}
